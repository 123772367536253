import { TourConfig } from "./tour-config-types";

export const tourConfigFactory = (uiState): TourConfig => {
  return [
    {
      id: "draw-areas",
      canBeShown: () => uiState.step.name === "areas",
      steps: [
        {
          id: "polygon-tool-select",
          controllerSelectorFn: () => document.querySelector("#polygon-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [-3, -3],
            padding: [6],
          },
          title: "tour.drawShapes.polygon.toolStep.title",
          description: "tour.drawShapes.polygon.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "polygon-placement-1",
          controllerSelectorFn: () =>
            document.querySelector(".irrigation-plan"),
          title: "tour.drawShapes.polygon.placementStep1.title",
          description: "tour.drawShapes.polygon.placementStep1.description",
        },
        {
          id: "polygon-placement-2",
          containerSelectorFn: () => document.querySelector("#main"),
          controllerSelectorFn: () =>
            document.querySelector("rect.irrigation-area-point"),
          title: "tour.drawShapes.polygon.placementStep1.title",
          description: "tour.drawShapes.polygon.placementStep1.description",
        },
        {
          id: "area-edit-select",
          containerSelectorFn: () => {
            const areaGElements = document.querySelectorAll("g.element.area");
            const areas = uiState.plan.getElementsByType("area");
            if (areaGElements.length !== areas.length)
              return areaGElements[areaGElements.length - 1].parentElement;

            const planAreasIds = areas.map((a) => `#${a.areaType}-${a.id}`);
            return document.querySelector(planAreasIds[planAreasIds.length - 1])
              ?.parentElement;
          },
          visualOptions: {
            clipType: "rectangle",
            padding: [32, 32, 32, 32],
          },
          title: "tour.drawShapes.polygon.placementStep2.title",
          description: "tour.drawShapes.polygon.placementStep2.description",
          isRelevant: () => uiState.plan?.areas?.length,
        },
        {
          id: "polygon-shape-setup",
          containerSelectorFn: () =>
            document.querySelector("#right-side-properties"),
          controllerSelectorFn: () =>
            document.querySelector("#right-side-submit"),
          visualOptions: {
            clipType: "shield",
          },
          title: "tour.drawShapes.polygon.propertiesStep.title",
          description: "tour.drawShapes.polygon.propertiesStep.description",
          isRelevant: () => uiState.plan?.areas?.length,
          action: () => {
            const areas = uiState.plan.getElementsByType("area");
            const area = areas[areas.length - 1];
            if (area != null) {
              uiState.setSelectedOtherTool();
              uiState.setSelectedTool("select");
              uiState.clearSelectedElement();

              uiState.setSelectedElement(area.id);
            }
          },
        },
        {
          id: "polygon-protect-area",
          controllerSelectorFn: () =>
            document.querySelector(".protect-element"),
          visualOptions: {
            clipType: "circle",
            offset: [-2, -2],
            padding: [4],
          },
          title: "tour.drawShapes.polygon.protectStep.title",
          description: "tour.drawShapes.polygon.protectStep.description",
          isRelevant: () => uiState.plan?.areas?.length,
          action: () => {
            const areas = uiState.plan.getElementsByType("area");
            const area = areas[areas.length - 1];
            if (area != null) {
              // HACK: for some reason selected element is cleared afterwards
              // if setSelectedElement called without setTimeout
              setTimeout(() => uiState.setSelectedElement(area.id), 0);
            }
          },
        },
        {
          id: "rectangle-tool-select",
          controllerSelectorFn: () => document.querySelector("#rectangle-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [-3, -3],
            padding: [6],
          },
          title: "tour.drawShapes.rectangle.toolStep.title",
          description: "tour.drawShapes.rectangle.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "rectangle-placement-1",
          controllerSelectorFn: () =>
            document.querySelector(".irrigation-plan"),
          title: "tour.drawShapes.rectangle.placementStep1.title",
          description: "tour.drawShapes.rectangle.placementStep1.description",
        },
        {
          id: "rectangle-shape-setup",
          containerSelectorFn: () => document.querySelector("#main"),
          controllerSelectorFn: () =>
            document.querySelector("#right-side-submit"),
          title: "tour.drawShapes.rectangle.propertiesStep.title",
          description: "tour.drawShapes.rectangle.propertiesStep.description",
        },
        {
          id: "circle-tool-select",
          controllerSelectorFn: () => document.querySelector("#circle-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [-3, -3],
            padding: [6],
          },
          title: "tour.drawShapes.circle.toolStep.title",
          description: "tour.drawShapes.circle.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "circle-placement-1",
          controllerSelectorFn: () =>
            document.querySelector(".irrigation-plan"),
          title: "tour.drawShapes.circle.placementStep1.title",
          description: "tour.drawShapes.circle.placementStep1.description",
        },
        {
          id: "circle-shape-setup",
          containerSelectorFn: () => document.querySelector("#main"),
          controllerSelectorFn: () =>
            document.querySelector("#right-side-submit"),
          title: "tour.drawShapes.circle.propertiesStep.title",
          description: "tour.drawShapes.circle.propertiesStep.description",
        },
        {
          id: "area-edit",
          containerSelectorFn: () => document.querySelector("#main"),
          controllerSelectorFn: () =>
            document.querySelector("#right-side-submit"),
          title: "tour.drawShapes.areaEditing.propertiesStep.title",
          description: "tour.drawShapes.areaEditing.propertiesStep.description",
        },
        {
          id: "area-move",
          containerSelectorFn: () => document.querySelector("#main"),
          title: "tour.drawShapes.areaMoving.propertiesStep.title",
          description: "tour.drawShapes.areaMoving.propertiesStep.description",
          isRelevant: () => uiState.plan?.areas?.length,
        },
        {
          id: "area-clone",
          containerSelectorFn: () => document.querySelector("#main"),
          title: "tour.drawShapes.areaCloning.propertiesStep.title",
          description: "tour.drawShapes.areaCloning.propertiesStep.description",
          isRelevant: () => uiState.plan?.areas?.length,
        },
        {
          id: "area-delete",
          controllerSelectorFn: () => document.querySelector(".delete-element"),
          visualOptions: {
            clipType: "circle",
            offset: [-2, -2],
            padding: [4],
          },
          title: "tour.drawShapes.areaDeleting.deleteStep.title",
          description: "tour.drawShapes.areaDeleting.deleteStep.description",
          isRelevant: () => uiState.plan?.areas?.length,
          action: () => {
            const areas = uiState.plan.getElementsByType("area");
            const area = areas[areas.length - 1];
            if (area != null) {
              uiState.setSelectedOtherTool();
              uiState.setSelectedTool("select");
              uiState.clearSelectedElement();

              uiState.setSelectedElement(area.id);
            }
          },
        },
        {
          id: "area-delete-all",
          controllerSelectorFn: () =>
            document.querySelector("#clear-items-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [-3, -3],
            padding: [6],
          },
          title: "tour.drawShapes.areaDeleting.deleteAll.title",
          description: "tour.drawShapes.areaDeleting.deleteAll.description",
          isRelevant: () => uiState.plan?.areas?.length,
        },
        {
          id: "undo-tool-select",
          controllerSelectorFn: () => document.querySelector("#undo-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [-3, 2],
            padding: [6],
          },
          title: "tour.drawShapes.undo.toolStep.title",
          description: "tour.drawShapes.undo.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "redo-tool-select",
          controllerSelectorFn: () => document.querySelector("#redo-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [-3, 2],
            padding: [6],
          },
          title: "tour.drawShapes.redo.toolStep.title",
          description: "tour.drawShapes.redo.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "ruler-tool-select",
          controllerSelectorFn: () => document.querySelector("#ruler-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [-3, -3],
            padding: [6],
          },
          title: "tour.drawShapes.ruler.toolStep.title",
          description: "tour.drawShapes.ruler.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "ruler-use",
          containerSelectorFn: () => document.querySelector("#main"),
          title: "tour.drawShapes.ruler.useStep.title",
          description: "tour.drawShapes.ruler.useStep.description",
        },
        {
          id: "final-step",
          containerSelectorFn: () => document.querySelector("#main"),
          title: "tour.drawShapes.final.title",
          description: "tour.drawShapes.final.description",
          isRelevant: () => true,
        },
      ],
      allowedElements: ["window-popup", "tour-info", "scale"],
    },
    {
      id: "system-tools",
      canBeShown: () => uiState.step.name === "system-elements",
      steps: [
        {
          id: "water-supply-select",
          controllerSelectorFn: () =>
            document.querySelector("#water-supply-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [2, -3],
            padding: [-4],
          },
          title: "tour.systemElements.waterSupply.toolStep.title",
          description: "tour.systemElements.waterSupply.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "water-supply-placement",
          controllerSelectorFn: () =>
            document.querySelector(".irrigation-plan"),
          title: "tour.systemElements.waterSupply.placementStep.title",
          description:
            "tour.systemElements.waterSupply.placementStep.description",
          validate: () => {
            return (
              uiState.selectedTool === "water-supply" ||
              uiState.plan.getSystemElementsByType("water-supply").length
            );
          },
        },
        {
          id: "water-supply-properties",
          containerSelectorFn: () =>
            document.querySelector("#right-side-properties"),
          controllerSelectorFn: () =>
            document.querySelector("#right-side-submit"),
          visualOptions: {
            clipType: "shield",
          },
          title: "tour.systemElements.waterSupply.propertiesStep.title",
          description:
            "tour.systemElements.waterSupply.propertiesStep.description",
          validate: () => {
            return uiState.plan.getSystemElementsByType("water-supply").length;
          },
          isRelevant: () => {
            return uiState.plan.getSystemElementsByType("water-supply").length;
          },
          action: () => {
            const waterSupply =
              uiState.plan.getSystemElementsByType("water-supply")[0];
            if (waterSupply != null) uiState.setSelectedElement(waterSupply.id);
          },
          excludedElements: ["delete-element"],
        },
        {
          id: "controller-select",
          controllerSelectorFn: () =>
            document.querySelector("#controller-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [2, -3],
            padding: [-4],
          },
          title: "tour.systemElements.controller.toolStep.title",
          description: "tour.systemElements.controller.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "controller-placement",
          controllerSelectorFn: () =>
            document.querySelector(".irrigation-plan"),
          title: "tour.systemElements.controller.placementStep.title",
          description:
            "tour.systemElements.controller.placementStep.description",
          validate: () =>
            uiState.selectedTool === "controller" ||
            uiState.plan.getSystemElementsByType("controller").length,
        },
        {
          id: "controller-properties",
          containerSelectorFn: () =>
            document.querySelector("#right-side-properties"),
          controllerSelectorFn: () =>
            document.querySelector("#right-side-submit"),
          visualOptions: {
            clipType: "shield",
          },
          title: "tour.systemElements.controller.propertiesStep.title",
          description:
            "tour.systemElements.controller.propertiesStep.description",
          isRelevant: () => {
            return uiState.plan.getSystemElementsByType("controller").length;
          },
          action: () => {
            document
              .querySelector("#right-side-properties")
              ?.classList.add("high-z");
            const controller =
              uiState.plan.getSystemElementsByType("controller")[0];
            if (controller != null) uiState.setSelectedElement(controller.id);
          },
          postAction: () => {
            document
              .querySelector("#right-side-properties")
              ?.classList.remove("high-z");
          },
          excludedElements: ["delete-element"],
        },
        {
          id: "valve-box-select",
          controllerSelectorFn: () => document.querySelector("#valve-box-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [2, -3],
            padding: [-4],
          },
          title: "tour.systemElements.valveBox.toolStep.title",
          description: "tour.systemElements.valveBox.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "valve-box-placement",
          controllerSelectorFn: () =>
            document.querySelector(".irrigation-plan"),
          title: "tour.systemElements.valveBox.placementStep.title",
          description: "tour.systemElements.valveBox.placementStep.description",
          validate: () =>
            uiState.selectedTool === "valve-box" ||
            uiState.plan.getSystemElementsByType("valve-box").length,
        },
        {
          id: "valve-box-properties",
          containerSelectorFn: () =>
            document.querySelector("#right-side-properties"),
          controllerSelectorFn: () =>
            document.querySelector("#right-side-submit"),
          visualOptions: {
            clipType: "shield",
          },
          title: "tour.systemElements.valveBox.propertiesStep.title",
          description:
            "tour.systemElements.valveBox.propertiesStep.description",
          isRelevant: () => {
            return uiState.plan.getSystemElementsByType("valve-box").length;
          },
          action: () => {
            document
              .querySelector("#right-side-properties")
              ?.classList.add("high-z");
            const valveBox =
              uiState.plan.getSystemElementsByType("valve-box")[0];
            if (valveBox != null) uiState.setSelectedElement(valveBox.id);
          },
          postAction: () => {
            document
              .querySelector("#right-side-properties")
              ?.classList.remove("high-z");
          },
          excludedElements: ["delete-element"],
        },
        {
          id: "combi-box-select",
          controllerSelectorFn: () => document.querySelector("#combi-box-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [2, -3],
            padding: [-4],
          },
          title: "tour.systemElements.combiBox.toolStep.title",
          description: "tour.systemElements.combiBox.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "combi-box-placement",
          controllerSelectorFn: () =>
            document.querySelector(".irrigation-plan"),
          title: "tour.systemElements.combiBox.placementStep.title",
          description: "tour.systemElements.combiBox.placementStep.description",
          validate: () =>
            uiState.selectedTool === "combi-box" ||
            uiState.plan.getSystemElementsByType("combi-box").length,
        },
        {
          id: "combi-box-properties",
          containerSelectorFn: () =>
            document.querySelector("#right-side-properties"),
          controllerSelectorFn: () =>
            document.querySelector("#right-side-submit"),
          visualOptions: {
            clipType: "shield",
          },
          title: "tour.systemElements.combiBox.propertiesStep.title",
          description:
            "tour.systemElements.combiBox.propertiesStep.description",
          isRelevant: () => {
            return uiState.plan.getSystemElementsByType("combi-box").length;
          },
          action: () => {
            const combiBox =
              uiState.plan.getSystemElementsByType("combi-box")[0];
            if (combiBox != null) uiState.setSelectedElement(combiBox.id);
          },
          excludedElements: ["delete-element"],
        },
        {
          id: "manual-water-socket-select",
          controllerSelectorFn: () => document.querySelector("#water-tap-tool"),
          visualOptions: {
            clipType: "circle",
            offset: [2, -3],
            padding: [-4],
          },
          title: "tour.systemElements.manualWaterSocket.toolStep.title",
          description:
            "tour.systemElements.manualWaterSocket.toolStep.description",
          isRelevant: () => true,
        },
        {
          id: "manual-water-socket-placement",
          controllerSelectorFn: () =>
            document.querySelector(".irrigation-plan"),
          title: "tour.systemElements.manualWaterSocket.placementStep.title",
          description:
            "tour.systemElements.manualWaterSocket.placementStep.description",
          validate: () =>
            uiState.selectedTool === "water-tap-point" ||
            uiState.plan.getSystemElementsByType("water-tap-point").length,
        },
      ],
      allowedElements: ["window-popup", "tour-info", "scale"],
    },
  ];
};
