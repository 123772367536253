import React from "react";
import { observer } from "mobx-react";
import useStores from "../../hooks/useStores";
import { useTourContext } from "../tour/TourContext";

function Tooltip({
  showTooltip,
  hideTooltip,
  position = "right",
  tooltip,
  className,
  children,
  ...props
}) {
  const popupRef = React.useRef();
  const tourContext = useTourContext();
  React.useEffect(() => {
    if (tourContext.tourEnabled) return;

    const tooltipNode = popupRef.current;
    const mouseOver = (e) => {
      const tooltipCoords = e.target.getBoundingClientRect();

      showTooltip(tooltip, { position, coords: tooltipCoords });
    };
    if (tooltipNode) {
      tooltipNode.addEventListener("mouseenter", mouseOver);
      tooltipNode.addEventListener("mouseleave", hideTooltip);
    }

    return () => {
      tooltipNode.removeEventListener("mouseenter", mouseOver);
      tooltipNode.removeEventListener("mouseleave", hideTooltip);
    };
  });

  return (
    <div {...props} ref={popupRef}>
      {children}
    </div>
  );
}

let TooltipWithState = ({ ...props }) => {
  const { uiState } = useStores();
  return (
    <Tooltip
      showTooltip={uiState.showTooltip}
      hideTooltip={uiState.hideTooltip}
      {...props}
    />
  );
};

TooltipWithState = observer(TooltipWithState);
export default TooltipWithState;
