import React from "react";
import ToolButton from "../elements/ToolButton";
import Tooltip from "../tooltip/Tooltip";

function ToolsItem({
  id,
  isActive,
  onClick = () => {},
  disabled = false,
  icon,
  hasLine = false,
  tooltip = null,
  lineBlurred,
}) {
  return (
    <React.Fragment>
      <ToolButton
        className={`tools-item ${hasLine ? "tools-no_margin" : ""} ${
          isActive ? "active" : ""
        } ${disabled ? "disabled" : ""}`}
        disabled={disabled}
        id={id}
        onClick={!disabled ? onClick : undefined}
      >
        <Tooltip position="right" tooltip={tooltip}>
          <i>{icon}</i>
        </Tooltip>
      </ToolButton>

      {hasLine ? (
        <div className={`tools-line ${lineBlurred ? "blurred" : ""}`}></div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default ToolsItem;
