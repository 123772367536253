import React from "react";
import { ReactComponent as ArrowUpSVG } from "./../../assets/arrow-up.svg";
import { ReactComponent as ArrowDownSVG } from "./../../assets/arrow-down.svg";

function Dropdown({ current, onChange, data, disabled = false }) {
  const [hasOpen, changeOpen] = React.useState(false);
  const dropdownRef = React.useRef();
  const containerRef = React.useRef();

  let currentLabel = data.find(({ value }) => value === current);

  if (currentLabel == null) {
    currentLabel = data[0];
  }

  const hideOutside = (e) => {
    e.preventDefault();
    const node = dropdownRef.current;
    if (hasOpen && node && !node.contains(e.target)) {
      changeOpen(false);
    }
  };

  React.useEffect(() => {
    const scrollNode = dropdownRef.current.closest(".scroll");
    const dropdownNode = dropdownRef.current;
    const containerNode = containerRef.current;
    const dropdownCoords = dropdownNode.getBoundingClientRect();

    if (hasOpen && containerNode) {
      let postitionTop = (containerNode.style.top =
        dropdownCoords.top + dropdownNode.clientHeight + "px");
      if (
        dropdownCoords.top +
          dropdownNode.clientHeight +
          containerNode.clientHeight >
        window.innerHeight
      ) {
        postitionTop = dropdownCoords.top - containerNode.clientHeight + "px";
      }

      containerNode.style.top = postitionTop;
      containerNode.style.width = dropdownNode.offsetWidth + "px";
      containerNode.style.visibility = "visible";
    }

    const scrollNodeListener = () => {
      changeOpen(false);
    };
    if (scrollNode != null) {
      scrollNode.addEventListener("scroll", scrollNodeListener);
    }
    window.addEventListener("resize", scrollNodeListener);
    document.addEventListener("mouseup", hideOutside);

    return () => {
      document.removeEventListener("mouseup", hideOutside);
      if (scrollNode != null) {
        scrollNode.removeEventListener("scroll", scrollNodeListener);
      }
      window.removeEventListener("resize", scrollNodeListener);
    };
  });

  if (!data) return null;

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div
        className={`dropdown-value ${disabled ? "inactive" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          if (!disabled) changeOpen(!hasOpen);
        }}
      >
        <div className="dropdown-text">
          {currentLabel ? currentLabel.label : null}
        </div>
        <div className="dropdown-arrow">
          <i>{hasOpen ? <ArrowUpSVG /> : <ArrowDownSVG />}</i>
        </div>
      </div>
      {hasOpen && (
        <div ref={containerRef} className="dropdown-container">
          {data.map((item, idx) => {
            return (
              <div
                key={item.value ?? "null"}
                className={`dropdown-item ${
                  current === item.value ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  onChange(item.value);
                  changeOpen(false);
                  dropdownRef.current?.dispatchEvent(
                    new CustomEvent("control-element-change", {
                      type: "dropdown",
                      value: item.value,
                    })
                  );
                }}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
