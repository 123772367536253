import React from "react";

function ToolButton({ className, children, onClick = () => {}, ...props }) {
  return (
    <button
      {...props}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={`tool ${className ? className : ""}`}
    >
      {children}
    </button>
  );
}

export default ToolButton;
