import { observer } from "mobx-react";
import React from "react";

import useStores from "../../hooks/useStores";
import { tourConfigFactory } from "./tour-config";
import { createTourDrawer } from "./tour-drawer";

export const Context = React.createContext({});

const converter = (element, container) => {
  const boundingRect = element.ref.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  if (element.type === "circle") {
    const padding = element.padding ? element.padding[0] : 0;
    return {
      id: element.id,
      type: "circle",
      x: boundingRect.left + element.offset[0],
      y: boundingRect.top - containerRect.top + element.offset[1],
      width: boundingRect.width + padding,
      height: boundingRect.width + padding,
    };
  }

  const [paddingTop, paddingRight, paddingBottom, paddingLeft] =
    element.padding == null ? [0, 0, 0, 0] : element.padding;
  if (
    element.type === "shield" ||
    element.type === "rectangle" ||
    element.type == null
  ) {
    return {
      id: element.id,
      type: element.type,
      x: boundingRect.left - paddingLeft,
      y: boundingRect.top - containerRect.top - paddingTop,
      width: boundingRect.width + paddingRight + paddingLeft,
      height: boundingRect.height + paddingTop + paddingBottom,
      ref: element.ref,
    };
  }
};

export default observer(function TourContextProvider({ children }) {
  const tourRef = React.useRef();

  const { uiState } = useStores();
  const { step } = uiState;

  const tourConfigs = tourConfigFactory(uiState);
  const [tourConfig, setTourConfig] = React.useState(
    tourConfigs.find((tc) => tc.canBeShown?.()) ?? tourConfigs[0]
  );
  const [tourEnabled, setTourEnabled] = React.useState(
    !localStorage.getItem(`tour-${tourConfig.id}-disabled`)
  );
  const [tourStepIndex, setTourStepIndex] = React.useState(0);
  const [tourStep, setTourStep] = React.useState(
    tourConfig?.steps[tourStepIndex]
  );
  const postActionCallback = React.useRef();

  const [highlightedElements, setHighlightedElements] = React.useState([]);
  const [clipPath, setClipPath] = React.useState();
  const observerRefs = React.useRef();

  const computePreviousRelevantStep = React.useCallback(() => {
    let previousAnchorStepIndex = tourStepIndex > 1 ? tourStepIndex - 1 : 0;
    for (; previousAnchorStepIndex >= 0; previousAnchorStepIndex--) {
      if (tourConfig.steps[previousAnchorStepIndex]?.isRelevant?.()) break;
    }

    return previousAnchorStepIndex;
  }, [tourConfig, tourStepIndex]);
  const computeNextRelevantStep = React.useCallback(
    (startIndex) => {
      const currentIndex = startIndex ?? tourStepIndex;
      let nextAnchorStepIndex = currentIndex + 1;
      for (
        ;
        nextAnchorStepIndex < tourConfig.steps.length;
        nextAnchorStepIndex++
      ) {
        if (tourConfig.steps[nextAnchorStepIndex]?.isRelevant?.()) break;
      }

      return nextAnchorStepIndex;
    },
    [tourConfig, tourStepIndex]
  );

  const previousStepAvailable = React.useMemo(() => {
    return tourStepIndex > 0;
  }, [tourStepIndex]);
  const nextStepAvailable = React.useMemo(() => {
    const nextAnchorStepIndex = computeNextRelevantStep();
    return nextAnchorStepIndex < tourConfig.steps.length;
  }, [computeNextRelevantStep, tourConfig.steps.length]);

  const [highlightTriggered, setHighlightTriggered] = React.useState(false);
  const highlightElement = React.useCallback(() => {
    if (highlightTriggered) return;

    setTimeout(() => {
      setHighlightTriggered(true);
      setTimeout(() => {
        setHighlightTriggered(false);
      }, 800);
    }, 75);
  }, [highlightTriggered]);

  const goToStep = React.useCallback(
    async (stepIndex) => {
      postActionCallback.current?.();
      postActionCallback.current = tourConfig.steps[stepIndex]?.postAction;

      const stepsCount = tourConfig.steps.length;
      if (stepIndex >= stepsCount) {
        localStorage.setItem(`tour-${tourConfig.id}-disabled`, "true");

        setTourEnabled(false);
        setTourStepIndex(0);
        setTourStep(tourConfig.steps[0]);

        return;
      }

      tourConfig.steps[stepIndex]?.action?.();
      if (observerRefs.current?.disconnect) {
        observerRefs.current.disconnect();
        delete observerRefs.current;
      }
      await forNextStepControlElement(tourConfig.steps[stepIndex]);

      setTourStepIndex(stepIndex);
      setTourStep(tourConfig.steps[stepIndex]);
      highlightElement();
    },
    [highlightElement, tourConfig.id, tourConfig.steps]
  );

  const forNextStepControlElement = async (nextStep) => {
    const elementSelectoFn =
      nextStep.controllerSelectorFn ?? nextStep.containerSelectorFn;
    if (elementSelectoFn == null)
      throw new Error("elementSelectoFn can't be null");

    return new Promise((res) => {
      let counter = 0;
      const interval = setInterval(() => {
        if (counter >= 500) {
          clearInterval(interval);
          throw new Error("Failed to locate tour element");
        }

        const element = elementSelectoFn();
        if (element !== null) {
          clearInterval(interval);
          res(true);
        }

        counter++;
      }, 10);
    });
  };

  const takeNextStep = React.useCallback(async () => {
    if (tourStepIndex + 1 >= tourConfig.steps.length) {
      localStorage.setItem(`tour-${tourConfig.id}-disabled`, "true");

      setTourEnabled(false);
      await goToStep(0);
      return;
    }

    await goToStep(tourStepIndex + 1);
  }, [goToStep, tourConfig.id, tourConfig.steps, tourStepIndex]);

  const clipElements = React.useCallback(
    (tourNode) => {
      const getHiglightElement =
        tourStep.containerSelectorFn ?? tourStep.controllerSelectorFn;
      const currentElementToHighlight = getHiglightElement();
      if (currentElementToHighlight == null) return;

      const highlightedElements = [
        {
          id: tourStep.id,
          type: tourStep.visualOptions?.clipType ?? "rectangle",
          padding: tourStep.visualOptions?.padding,
          offset: tourStep.visualOptions?.offset ?? [0, 0],
          ref: currentElementToHighlight,
        },
      ].map((el) => converter(el, tourNode));
      setHighlightedElements(highlightedElements);
      setClipPath(
        createTourDrawer().draw(
          highlightedElements,
          tourNode.clientWidth,
          tourNode.clientHeight
        )
      );

      let index = 0;
      observerRefs.current = new ResizeObserver((entries) => {
        if (index === 0) {
          index++;
          return;
        }

        const highlightedElements = [
          {
            id: tourStep.id,
            type: tourStep.visualOptions?.clipType ?? "rectangle",
            padding: tourStep.visualOptions?.padding,
            offset: tourStep.visualOptions?.offset ?? [0, 0],
            ref: currentElementToHighlight,
          },
        ].map((el) => converter(el, tourNode));

        setHighlightedElements(highlightedElements);
        setClipPath(
          createTourDrawer().draw(
            highlightedElements,
            tourNode.clientWidth,
            tourNode.clientHeight
          )
        );
      });

      const container = tourStep.containerSelectorFn?.();
      if (container && container.tagName !== "g") {
        observerRefs.current.observe(container);
      }
    },
    [tourStep]
  );

  const showTour = React.useCallback(() => {
    setTourEnabled(true);
  }, []);
  const hideTour = React.useCallback(() => {
    setTourEnabled(false);
    localStorage.setItem(`tour-${tourConfig.id}-disabled`, "true");
  }, [tourConfig.id]);

  React.useEffect(() => {
    const tourNode = tourRef.current;
    if (tourNode == null) return;

    clipElements(tourNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourEnabled]);

  React.useEffect(() => {
    if (!tourConfig.canBeShown()) return;

    const tourNode = tourRef.current;
    if (tourNode == null) return;

    const resizeListener = (e) => {
      clipElements(tourNode);
    };
    window.addEventListener("resize", resizeListener);

    clipElements(tourNode);

    return () => window.removeEventListener("resize", resizeListener);
  }, [clipElements, step.name, tourConfig]); // do not remove step.name!

  React.useEffect(() => {
    const nextTourConfig = tourConfigs.find((tc) => tc.canBeShown?.());
    if (nextTourConfig == null || nextTourConfig.id === tourConfig.id) return;

    setTourConfig(nextTourConfig);
    setTourStepIndex(0);
    setTourStep(nextTourConfig.steps[0]);
    if (localStorage.getItem(`tour-${nextTourConfig.id}-disabled`) == null) {
      showTour();
    }
  }, [showTour, tourConfig.id, tourConfigs]);

  return (
    <Context.Provider
      value={React.useMemo(
        () => ({
          allowedElements: tourConfig.allowedElements ?? [],
          clipPath,
          computeNextRelevantStep,
          computePreviousRelevantStep,
          goToStep,
          hideTour,
          highlightedElements,
          highlightElement,
          highlightTriggered,
          nextStepAvailable,
          previousStepAvailable,
          setTourEnabled,
          showTour,
          takeNextStep,
          tourCanBeShown: tourConfig.canBeShown,
          tourEnabled,
          tourRef,
          tourStep,
        }),
        [
          clipPath,
          computeNextRelevantStep,
          computePreviousRelevantStep,
          goToStep,
          hideTour,
          highlightedElements,
          highlightElement,
          highlightTriggered,
          nextStepAvailable,
          previousStepAvailable,
          showTour,
          takeNextStep,
          tourConfig,
          tourEnabled,
          tourStep,
        ]
      )}
    >
      {children}
    </Context.Provider>
  );
});

export function useTourContext() {
  return React.useContext(Context);
}
