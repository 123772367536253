import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as ShouldBeIrregatedSVG } from "./../../assets/should-be-irrigated-tour.svg";
import { ReactComponent as DriplineSVG } from "./../../assets/stripline-tour.svg";
import { ReactComponent as NoIrregationSVG } from "./../../assets/no-irrigation-tour.svg";
import { ReactComponent as CanBeIrregatedSVG } from "./../../assets/can-be-irrigated-tour.svg";
import { ReactComponent as CrossAbilitySVG } from "./../../assets/cross-ability-tour.svg";

import { useTourContext } from "./TourContext";
import useStores from "../../hooks/useStores";

const elementsContainClass = (elements, className) =>
  elements.find((el) => el.classList.contains(className));

function useButtonHandlers(ref, ctx) {
  React.useEffect(() => {
    const tourOverlayNode = ref.current;
    if (tourOverlayNode == null) return;

    const buttonListener = async (e) => {
      if (e.code === "Escape") {
        e.preventDefault();
        e.stopPropagation();

        ctx.highlightElement();
        return;
      }

      if (e.code === "Delete" && e.target.tagName.toLowerCase() !== "input") {
        e.preventDefault();
        e.stopPropagation();

        ctx.highlightElement();
        return;
      }

      if (
        e.target.tagName.toLowerCase() === "input" &&
        e.type === "keyup" &&
        e.code === "Enter"
      ) {
        if (
          typeof ctx.tourStep.validate === "function" &&
          !ctx.tourStep.validate()
        ) {
          e.preventDefault();
          e.stopPropagation();

          //TODO:(oleg) discuss possible ways to notify user
          return;
        }

        await ctx.takeNextStep();
      }
    };

    document.addEventListener("keydown", buttonListener, true);
    document.addEventListener("keyup", buttonListener, true);

    return () => {
      document.removeEventListener("keyup", buttonListener, true);
      document.removeEventListener("keydown", buttonListener, true);
    };
  }, [ctx, ctx.tourStep, ref]);
}

function useClickHandlers(ref, ctx) {
  React.useEffect(() => {
    const tourOverlayNode = ref.current;
    if (tourOverlayNode == null) return;

    const listener = async (e) => {
      const elements = document.elementsFromPoint(e.clientX, e.clientY);
      // allow elements from Scale toolbar to be clicked
      if (
        ctx.allowedElements.some((ex) => elementsContainClass(elements, ex))
      ) {
        return true;
      }

      // disable clicks on excluded elements from step
      if (
        ctx.tourStep.excludedElements?.some((ex) =>
          elementsContainClass(elements, ex)
        )
      ) {
        e.preventDefault();
        e.stopPropagation();

        ctx.highlightElement();
        return;
      }

      const controllerElement = ctx.tourStep.controllerSelectorFn?.();
      const containerElement = ctx.tourStep.containerSelectorFn?.();
      if (
        typeof ctx.tourStep.containerSelectorFn === "function" &&
        containerElement?.contains(e.target) &&
        !controllerElement?.contains(e.target)
      ) {
        return true;
      }

      // propagate mousedown only to plan, areas and <input> elements
      if (
        e.type === "mousedown" &&
        !controllerElement?.classList?.contains("irrigation-plan") &&
        !controllerElement?.classList?.contains("irrigation-area-point") &&
        e.target.tagName.toLowerCase() !== "input"
      ) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      // plan doesn't handle click events
      if (
        e.type === "click" &&
        controllerElement?.classList?.contains("irrigation-plan")
      ) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      if (!controllerElement?.contains(e.target)) {
        e.preventDefault();
        e.stopPropagation();

        ctx.highlightElement();
        return;
      }

      if (
        typeof ctx.tourStep.validate === "function" &&
        !ctx.tourStep.validate()
      ) {
        e.preventDefault();
        e.stopPropagation();

        //TODO:(oleg) discuss possible ways to notify user
        return;
      }

      await ctx.takeNextStep();
    };

    document.addEventListener("mousedown", listener, true);
    document.addEventListener("click", listener, true);

    return () => {
      document.removeEventListener("click", listener, true);
      document.removeEventListener("mousedown", listener, true);
    };
  }, [ctx, ref]);
}

function Tour() {
  const ctx = useTourContext();
  const ref = ctx.tourRef;

  useButtonHandlers(ref, ctx);
  useClickHandlers(ref, ctx);

  if (!ctx.tourEnabled) return null;
  if (!ctx.tourCanBeShown()) return null;

  return (
    <div
      className="tour"
      style={{ clipPath: `path('${ctx.clipPath}')` }}
      ref={ref}
    >
      {ctx.highlightedElements.map((el) => {
        const elementType = el.type;
        const borderWidth = 2;
        const style = {
          width: `${el.width + borderWidth * 2}px`,
          height: `${el.height + borderWidth * 2}px`,
          left: `${el.x - borderWidth}px`,
          top: `${el.y - borderWidth}px`,
          borderWidth: `${borderWidth}px`,
        };

        return (
          <div key={el.id}>
            <div className={`element-highlight ${elementType}`} style={style} />
            <div className={`animated-shadow  ${elementType}`} style={style} />
          </div>
        );
      })}
    </div>
  );
}
export default observer(Tour);

export const TourInfo = observer(function () {
  const ctx = useTourContext();
  const { uiState } = useStores();
  const { formatMessage } = useIntl();

  if (!ctx.tourEnabled) return null;
  if (!ctx.tourCanBeShown()) return null;

  const templateReplacements = {
    waterVolumeInfoURL: (
      <a
        className="link"
        target="_blank"
        rel="noreferrer noopener"
        href={formatMessage({
          id: "texts.properties.systemElements.waterSupply.waterVolumeInfoURL",
        })}
      >
        {formatMessage({
          id: "tour.systemElements.waterSupply.propertiesStep.description.infoLinkTitle",
        })}
      </a>
    ),
    videoLink: (
      <a
        className="link"
        target="_blank"
        rel="noreferrer noopener"
        href={formatMessage({
          id: "texts.steps.systemElements.waterSupplyDialog.videoLink",
        })}
      >
        {formatMessage({
          id: "tour.systemElements.waterSupply.propertiesStep.description.videoLinkTitle",
        })}
      </a>
    ),
    shouldBeIrregatedIcon: <ShouldBeIrregatedSVG />,
    driplineIcon: <DriplineSVG />,
    noIrregationIcon: <NoIrregationSVG />,
    canBeIrregatedIcon: <CanBeIrregatedSVG />,
    crossAbilityIcon: <CrossAbilitySVG />,
    ul: (chunks) => <div className="tour-info-steps-icon-list">{chunks}</div>,
  };

  return (
    <div className="tour-info">
      <div className="tour-info-title">
        {formatMessage({ id: ctx.tourStep.title })}
      </div>
      <div className="tour-info-description">
        <FormattedMessage
          id={ctx.tourStep.description}
          values={templateReplacements}
        />
      </div>
      <div className="tour-info-controls">
        <button
          onClick={async () => {
            ctx.hideTour();
            await ctx.goToStep(0);
          }}
        >
          {formatMessage({ id: "tour.tourInfo.leave.button" })}
        </button>

        <div className="step-controls">
          <button
            className="with-margin"
            disabled={!ctx.previousStepAvailable}
            onClick={async () => {
              if (
                typeof ctx.tourStep.validate === "function" &&
                !ctx.tourStep.validate()
              ) {
                ctx.highlightElement();

                return;
              }

              const previousIndex = ctx.computePreviousRelevantStep();
              if (previousIndex >= 0) {
                uiState.setSelectedOtherTool();
                uiState.setSelectedTool("select");
                uiState.clearSelectedElement();

                await ctx.goToStep(previousIndex);
              }
            }}
          >
            {"<"}
          </button>
          <button
            id="tour-button-next"
            disabled={!ctx.nextStepAvailable}
            onClick={async () => {
              if (
                typeof ctx.tourStep.validate === "function" &&
                !ctx.tourStep.validate()
              ) {
                ctx.highlightElement();

                return;
              }

              uiState.setSelectedOtherTool();
              uiState.setSelectedTool("select");
              uiState.clearSelectedElement();

              const nextIndex = ctx.computeNextRelevantStep();
              await ctx.goToStep(nextIndex);
            }}
          >
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
});
