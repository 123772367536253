import React from "react";
import { observer } from "mobx-react";

import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";
import Tooltip from "../tooltip/Tooltip";

const WaterDropIcon = ({ fill = "#c3c3c3" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="24"
      viewBox="0 0 16 24"
    >
      <path
        d="M8.008 0S.076 11.329.013 15.838A.558.558 0 0 1 0 15.9 8.057 8.057 0 0 0 8.008 24a8.047 8.047 0 0 0 8-8.1v-.064c-.08-4.507-8-15.836-8-15.836m5.608 15.863a2.05 2.05 0 1 1-4.1 0v-.026a2.05 2.05 0 1 1 4.1 0z"
        fill={fill}
      />
    </svg>
  );
};

const getDisributionQuality = (value) => {
  if (value < 70) return "bad";
  if (value >= 70 && value <= 77) return "good";
  if (value >= 78 && value <= 85) return "excellent";
  if (value >= 86) return "exceptional";
};

const colorMapping = {
  default: "#c3c3c3",
  bad: "#ff7600",
  good: "#ffe200",
  excellent: "#c5d75d",
  exceptional: "#33bc12",
};
const coloredDropsCountMapping = {
  bad: 1,
  good: 2,
  excellent: 3,
  exceptional: 4,
};
const getDisributionColoring = (uniformity) => {
  const quality = getDisributionQuality(uniformity);

  let result = [];
  for (let i = 0; i < 4; i++) {
    if (i >= coloredDropsCountMapping[quality]) {
      result.push(colorMapping["default"]);
      continue;
    }

    result.push(colorMapping[quality]);
  }
  return result;
};

// const descriptionMapping = {
//   exceptional:
//     "texts.steps.sprinklers.coverage.distribution.exceptional.tooltip.text",
//   excellent:
//     "texts.steps.sprinklers.coverage.distribution.excellent.tooltip.text",
//   good: "texts.steps.sprinklers.coverage.distribution.good.tooltip.text",
//   bad: "texts.steps.sprinklers.coverage.distribution.bad.tooltip.text",
// };

// const getDisributionTooltip = (uniformity) => {
//   const quality = getDisributionQuality(uniformity);
//   return descriptionMapping[quality];
// };

const titleMapping = {
  exceptional:
    "texts.steps.sprinklers.coverage.distribution.exceptional.title.text",
  excellent:
    "texts.steps.sprinklers.coverage.distribution.excellent.title.text",
  good: "texts.steps.sprinklers.coverage.distribution.good.title.text",
  bad: "texts.steps.sprinklers.coverage.distribution.bad.title.text",
};
const getDisributionTitleId = (uniformity) => {
  const quality = getDisributionQuality(uniformity);
  return titleMapping[quality];
};

const SprinklersCoverageLegend = ({ legend, uniformityValues }) => {
  const { formatMessage } = useIntl();

  const tooltipItems = [];
  let tooltipMessages, colorDistribution, distributionTitleId;
  if (uniformityValues) {
    const {
      BAD_VALUES_CNT_IN_P_THRESHOLD,
      underValuesPercent,
      overValuesPercent,
      uniformity,
      score,
    } = uniformityValues;

    const valuesForCheck = [
      {
        value: underValuesPercent,
        text: "texts.steps.sprinklers.coverage.distribution.underValuesCnt.tooltip.text",
      },
      {
        value: overValuesPercent,
        text: "texts.steps.sprinklers.coverage.distribution.overValuesCnt.tooltip.text",
      },
    ];

    let v = uniformity;

    for (let el of valuesForCheck) {
      if (el.value > BAD_VALUES_CNT_IN_P_THRESHOLD) {
        v = 0;
        tooltipItems.push({
          color: colorMapping.bad,
          value: el.value,
          text: formatMessage({ id: el.text }),
        });
      }
    }

    if (uniformity) {
      tooltipItems.push({
        color: getDisributionColoring(uniformity)[0],
        value: uniformity,
        text: "Precipitation uniformity",
      });
    }

    if (score) {
      const value = 100 - score;
      tooltipItems.push({
        color: getDisributionColoring(score)[0],
        value: value > 0 ? `+${value}` : value,
        text: "Additional irrigation time",
      });
    }

    tooltipMessages = `
    <ul class="tooltip-messages-list">
      ${tooltipItems
        .map(
          ({ value, color, text }) =>
            `<li style="color: ${color}"><span><b>${value}%</b>: ${text}<span></li>`
        )
        .join("")}
    </ul>`;

    colorDistribution = getDisributionColoring(v);
    distributionTitleId = getDisributionTitleId(v);
  }

  return (
    <div
      className={`coverage-legend-wrapper ${
        uniformityValues ? "with-uniformity" : ""
      }`}
    >
      <div className="coverage-legend">
        {legend.map(({ color, label }) => {
          return (
            <div key={label} className="legend-item">
              <i style={{ background: color }}></i>
              {formatMessage({ id: label })}
            </div>
          );
        })}
      </div>
      {uniformityValues != null && (
        <div className="distribution-uniformity-legend">
          <div className="distribution-uniformity-title">
            {formatMessage({
              id: "texts.steps.sprinklers.coverage.distribution.title",
            })}
          </div>
          <Tooltip position="top" tooltip={tooltipMessages}>
            <div className="water-drops">
              {colorDistribution.map((fillColor, index) => {
                return (
                  <i key={index + fillColor}>
                    {<WaterDropIcon fill={fillColor} />}
                  </i>
                );
              })}
            </div>
          </Tooltip>
          <div>{formatMessage({ id: distributionTitleId })}</div>
        </div>
      )}
    </div>
  );
};

let SprinklersCoverageLegendWithState = () => {
  const { uiState } = useStores();

  return (
    <>
      {uiState.plan?.precipitationCoverage &&
        uiState.calculatingTitle == null &&
        uiState.stepIdx === uiState.stepIdxByName("sprinklers") && (
          <SprinklersCoverageLegend
            legend={uiState.settingsState.precipitationBins}
            uniformityValues={uiState.plan?.uniformity}
          />
        )}
    </>
  );
};

SprinklersCoverageLegendWithState = observer(SprinklersCoverageLegendWithState);
export default SprinklersCoverageLegendWithState;
