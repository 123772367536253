import React from "react";
import { observer } from "mobx-react";

import { ReactComponent as FreeSVG } from "./../../assets/free.svg";
import { ReactComponent as QuadratSVG } from "./../../assets/quadrat.svg";
import { ReactComponent as CircleSVG } from "./../../assets/circle.svg";
import ToolsItem from "./ToolsItem";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function ShapeTools({ selectedTool, setSelectedTool, labels }) {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <ToolsItem
        id={"polygon-tool"}
        icon={<FreeSVG />}
        type="polygon"
        isActive={selectedTool === "polygon"}
        onClick={() => {
          setSelectedTool("polygon");
        }}
        tooltip={
          labels ? formatMessage({ id: labels.freeDrawing.tooltip }) : null
        }
      />

      <ToolsItem
        id={"rectangle-tool"}
        icon={<QuadratSVG />}
        type="rectangle"
        isActive={selectedTool === "rectangle"}
        onClick={() => {
          setSelectedTool("rectangle");
        }}
        tooltip={
          labels ? formatMessage({ id: labels.rectangle.tooltip }) : null
        }
      />
      <ToolsItem
        id={"circle-tool"}
        icon={<CircleSVG />}
        hasLine={true}
        type="circle"
        isActive={selectedTool === "circle"}
        onClick={() => {
          setSelectedTool("circle");
        }}
        tooltip={labels ? formatMessage({ id: labels.circle.tooltip }) : null}
      />
    </React.Fragment>
  );
}

let ShapeToolsWithState = () => {
  const { uiState } = useStores();
  return (
    <ShapeTools
      selectedTool={uiState.selectedTool}
      setSelectedTool={uiState.setSelectedTool}
      labels={uiState.settingsState ? uiState.settingsState.texts.tools : null}
    />
  );
};

ShapeToolsWithState = observer(ShapeToolsWithState);
export default ShapeToolsWithState;
