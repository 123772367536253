import React from "react";
import { observer } from "mobx-react";

import { ReactComponent as SelectSVG } from "./../../assets/select.svg";
import { ReactComponent as ClearSVG } from "./../../assets/clear.svg";
import { ReactComponent as RuleSVG } from "./../../assets/rule.svg";
import { ReactComponent as UndoSVG } from "./../../assets/undo.svg";
import { ReactComponent as RedoSVG } from "./../../assets/redo.svg";

import Popup from "../popups/Popup";
import ShapeTools from "./ShapeTools";
import ToolsItem from "./ToolsItem";
import SprinklerTools from "./SprinklerTools";
import SystemElementTools from "./SystemElementTools";
import PipelineTools from "./PipelineTools";
import SensorTools from "./SensorTools";
import Scroll from "../elements/Scroll";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function ToolsPopup({ text }) {
  const [hasActive, changeActive] = React.useState(true);
  if (!hasActive) return null;

  return (
    <div className="popup-item">
      <Popup
        className="start-draw"
        hasActive={hasActive}
        position="right"
        hasCloseButton={false}
        onClose={() => {
          changeActive(false);
        }}
      >
        <div className="text-popup">{text}</div>
      </Popup>
    </div>
  );
}

function Tools({
  step,
  clearItemsEnabled,
  clearCurrentItems,
  selectedTool,
  setSelectedTool,
  hasPopup,
  showConfirm,
  undo,
  redo,
  scale,
  labels,
  planIsEditable,
}) {
  const parentRef = React.useRef(null);
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (document?.activeElement?.disabled) {
      parentRef?.current?.focus();
    }
  });

  let ToolsElement;
  let confirmTexts;
  if (scale != null) {
    switch (step.name) {
      case "areas":
        ToolsElement = <ShapeTools />;
        confirmTexts = {
          tooltip: "texts.tools.clear.areas.tooltip",
          title: "texts.tools.clear.areas.tooltip",
          description: "dialog.clearItems.areas.text",
        };
        break;
      case "sprinklers":
        ToolsElement = <SprinklerTools />;
        confirmTexts = {
          tooltip: "texts.tools.clear.sprinklers.tooltip",
          title: "texts.tools.clear.sprinklers.tooltip",
          description: "dialog.clearItems.sprinklers.text",
        };
        break;
      case "system-elements":
        ToolsElement = <SystemElementTools />;
        confirmTexts = {
          tooltip: "texts.tools.clear.systemElements.tooltip",
          title: "texts.tools.clear.systemElements.tooltip",
          description: "dialog.clearItems.systemElements.text",
        };
        break;
      case "pipeline":
        ToolsElement = <PipelineTools />;
        confirmTexts = {
          tooltip: "texts.tools.clear.pipeline.tooltip",
          title: "texts.tools.clear.pipeline.tooltip",
          description: "dialog.clearItems.pipeline.text",
        };
        break;
      case "sensor":
        ToolsElement = <SensorTools />;
        confirmTexts = {
          tooltip: "texts.tools.clear.sensor.tooltip",
          title: "texts.tools.clear.sensor.tooltip",
          description: "dialog.clearItems.sensor.text",
        };
        break;
      default:
        ToolsElement = null;
        confirmTexts = {
          tooltip: "texts.tools.clear.tooltip",
          title: "dialog.clearItems.title",
          description: "dialog.clearItems.text",
        };
        break;
    }
  }

  if (ToolsElement == null) return null;

  return (
    <div className={`tools ${step.name}`}>
      {hasPopup ? (
        <ToolsPopup text={formatMessage({ id: labels.startToDraw })} />
      ) : null}
      <Scroll className="tools-wrap">
        <div className="tools-content" ref={parentRef} tabIndex="0">
          <ToolsItem
            icon={<SelectSVG />}
            type="select"
            isActive={selectedTool === "select"}
            onClick={() => {
              setSelectedTool("select");
            }}
            tooltip={
              labels ? formatMessage({ id: labels.select.tooltip }) : null
            }
          />

          {planIsEditable ? ToolsElement : null}

          <ToolsItem
            id="ruler-tool"
            icon={<RuleSVG />}
            hasLine={true}
            type="ruler"
            isActive={selectedTool === "ruler"}
            onClick={() => {
              setSelectedTool("ruler");
            }}
            tooltip={
              labels ? formatMessage({ id: labels.ruler.tooltip }) : null
            }
          />

          <ToolsItem
            id="undo-tool"
            icon={<UndoSVG />}
            type="undo"
            isActive={false}
            disabled={undo == null || !planIsEditable}
            onClick={undo ? undo : () => {}}
            tooltip={labels ? formatMessage({ id: labels.undo.tooltip }) : null}
          />
          <ToolsItem
            id="redo-tool"
            icon={<RedoSVG />}
            type="redo"
            hasLine={true}
            lineBlurred={true}
            isActive={false}
            disabled={redo == null || !planIsEditable}
            onClick={redo ? redo : () => {}}
            tooltip={labels ? formatMessage({ id: labels.redo.tooltip }) : null}
          />

          <ToolsItem
            id="clear-items-tool"
            icon={<ClearSVG />}
            disabled={!clearItemsEnabled}
            onClick={async () => {
              await showConfirm({
                title: formatMessage({
                  id: confirmTexts.title,
                }),
                description: formatMessage({
                  id: confirmTexts.description,
                }),
                confirm: () => clearCurrentItems(),
              });
            }}
            tooltip={formatMessage({ id: confirmTexts.tooltip })}
          />
        </div>
      </Scroll>
    </div>
  );
}

let ToolsWithState = () => {
  const { uiState } = useStores();
  return (
    <Tools
      clearItemsEnabled={uiState.clearItemsEnabled}
      clearCurrentItems={uiState.clearCurrentItems}
      selectedTool={uiState.selectedTool}
      setSelectedTool={uiState.setSelectedTool}
      step={uiState.step}
      hasPopup={
        uiState.plan
          ? uiState.step.name === "areas" &&
            uiState.plan.areas.length === 0 &&
            ((uiState.plan.opacity != null && uiState.selectedTool == null) ||
              uiState.plan.background == null)
          : false
      }
      showConfirm={uiState.showConfirm}
      undo={uiState.undo}
      redo={uiState.redo}
      scale={uiState.plan ? uiState.plan.scale : undefined}
      labels={uiState.settingsState ? uiState.settingsState.texts.tools : null}
      planIsEditable={uiState.planIsEditable}
    />
  );
};

ToolsWithState = observer(ToolsWithState);

export default ToolsWithState;
