import React from "react";
import { observer } from "mobx-react";

import Scroll from "../elements/Scroll";
import ToolButton from "../elements/ToolButton";

import { ReactComponent as UnlockSVG } from "./../../assets/unlock.svg";
import { ReactComponent as CloseDisabledSVG } from "./../../assets/close-disabled.svg";
import { ReactComponent as LockSVG } from "./../../assets/lock.svg";
import { ReactComponent as CloseSVG } from "./../../assets/close.svg";
import { ReactComponent as SaveSVG } from "./../../assets/save.svg";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function Properties({
  children,
  header,
  onDisable,
  disabled,
  onRemove,
  onChange,
  clearSelected,
  hasClose = true,
  hasTitle = true,
  hasScroll = true,
  labels,
  planIsEditable = true,
}) {
  const { formatMessage } = useIntl();

  const onRemoveEl =
    onRemove != null && planIsEditable
      ? () => {
          if (!disabled) {
            onRemove();
          }
        }
      : null;

  const Component = hasScroll ? Scroll : "div";

  return (
    <form
      onKeyUp={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          clearSelected();
        }
      }}
    >
      <Component className="right-side-content properties">
        <div className="properties-header">
          {hasTitle ? (
            <strong className="properties-title">
              {formatMessage({ id: labels.title })}
            </strong>
          ) : null}
          {header ? (
            <div className="properties-element">
              <div className="element-icon">{header.icon}</div>
              <div className="element-title">{header.title}</div>
            </div>
          ) : null}
        </div>
        <div className="properties-menu">
          <ToolButton
            className={`${!planIsEditable ? "inactive" : ""} protect-element`}
          >
            <i
              onClick={() => {
                if (onDisable != null && planIsEditable) {
                  onDisable();
                  onChange();
                }
              }}
            >
              {disabled || !planIsEditable ? <LockSVG /> : <UnlockSVG />}
            </i>
          </ToolButton>
          <ToolButton
            className={`${!planIsEditable ? "inactive" : ""} delete-element`}
          >
            <i onClick={onRemoveEl}>
              {onRemoveEl == null || disabled ? (
                <CloseDisabledSVG />
              ) : (
                <CloseSVG />
              )}
            </i>
          </ToolButton>
        </div>
        {children}
      </Component>
      <div className="right-side-footer">
        {hasClose && (
          <div className="right-side-icon">
            <ToolButton
              id="right-side-submit"
              type="submit"
              onClick={() => {
                clearSelected();
              }}
            >
              <SaveSVG />
            </ToolButton>
          </div>
        )}
      </div>
    </form>
  );
}

let PropertiesWithState = ({ ...props }) => {
  const { uiState } = useStores();
  return (
    <Properties
      {...props}
      planIsEditable={uiState.planIsEditable}
      clearSelected={uiState.plan ? uiState.clearSelectedElement : () => {}}
      labels={
        uiState.settingsState ? uiState.settingsState.texts.properties : {}
      }
    />
  );
};

PropertiesWithState = observer(PropertiesWithState);
export default PropertiesWithState;
